@import 'vars'

#subscription
  &.-left > .section-img
    right: calc(4rem + #{$statue-margin})
    left: calc(50% - #{$statue-margin})

  &.-right > .section-img
    right: calc(4rem - #{$statue-margin})
    left: calc(50% + #{$statue-margin})

  > .section-img
    left: 50%
    right: 4rem
    top: unset
    bottom: -30%

    img
      width: 120%
      transition: all $slide-transition-duration ease-in-out

      @media (max-width: $lg)
        width: 150%

  > .info-block 
    width: 60%

  > .content 
    padding: 0 $mobile-wrapper-padding
    width: 100%

.subscription-info 
  display: grid
  grid-template-columns: repeat(2, 1fr)
  grid-template-rows: repeat(4, 1fr)
  grid-template-areas: "qcol1 ." "qcol1 qcol2" "qcol1 qcol2" ". qcol2"
    
  grid-column-gap: 1.5rem
  grid-row-gap: 2rem

  @media (max-width: $lg)
    display: flex
    flex-direction: column

  @media (max-width: $md)
    gap: 0
    display: flex
    flex-direction: column

.subscription__title
  display: flex
  align-items: center
  gap: 15rem 

.subscription__questions-col 
  display: flex
  flex-direction: column
  gap: 10px

.qcol 
  display: flex
  flex-direction: column
  gap: 2rem

  @media (max-width: $md)
    gap: 0

.subscription__img 
  @media (max-width: $md)
    display: none


